import {
  getPurchaseReturnList,
  exportOrder, //导出
} from '../api'
import Util from "@/utils/utils";
import { salesReturn } from "@/views/channelReturns/distributorReturnList/api";
import returnTypeSelect from './../purchaseGenuineList/components/returnTypeSelect'
export default {
  name: 'scaleOrderList',
  components: {
    returnTypeSelect,
  },
  data() {
    return {
      breadcrumbData: [{
        path: "/index",
        name: "index",
        title: "首页",
      },
        {
          path: "/purchaseReturn/purchaseReturnList",
          name: "",
          title: "采购退货",
        },
      ],
      navList: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        sortName:'documentDate',
        sortOrder:'desc'
      },
      goodsList: [],
      invalidList: [], //批量失效
      insearchOBJ: [],
      paramList: [],
      searchzz: false,
      loadings: false,
      // 暂无数据
      NoDate: false,
      nolistImg: require("@/assets/order/noList.png"),
      // 暂无更能多数据
      noMore: false,
      // 查看更多
      loadMores: true,
      goodsNmuber: 0,
      pageLoadFlag: false,
      requestdata: {

      },
      isLoading: false,
      visibleCancel:false,
      cancelDocumentNum:'',
      creatDate: 1,//排序的图标标识
      returnId:'',// 退残的id
      selectReturnTypeWindow:false,
      orderLoading:false
    }
  },
  filters: {
    formatDate: function (value) {
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? ('0' + MM) : MM;
      let d = date.getDate();
      d = d < 10 ? ('0' + d) : d;
      let h = date.getHours();
      h = h < 10 ? ('0' + h) : h;
      let m = date.getMinutes();
      m = m < 10 ? ('0' + m) : m;
      let s = date.getSeconds();
      s = s < 10 ? ('0' + s) : s;
      return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
    }
  },
  beforeCreate() {
    // 添加背景色
    document.querySelector('body').setAttribute('style', 'background-color:#F7F7F7')
  },
  beforeDestroy() {
    window.removeEventListener("storage", function (e) {
    });
    document.querySelector('body').setAttribute('style', '')
  },

  mounted() {

  },
  methods: {
    // 点击退货 打开选择弹窗
    returnOrder(item){
      // this.returnId = item.purchaseOrderId
      // this.selectReturnTypeWindow = true
      let routeUrl = this.$router.resolve({
        path: '/purchaseReturn/purchaseReturnEntry',
        query: {
          id:item.purchaseOrderId,
          source:'th'
        }
      });
      window.open(routeUrl.href, "_blank");
    },

    // 选择的是正品还是残次退 跳转链接不一样
    getType(data){
      // 1 是正品 2是残次退
      let routeUrl = ''
      if(data === 1){
        routeUrl = this.$router.resolve({
          path: '/purchaseReturn/purchaseReturnEntry',
          query: {
            id:this.returnId,
            source:'th'
          }
        });

      }else{
        routeUrl = this.$router.resolve({
          path: '/defectiveRegression/defectiveInput',
          query: {
            id:this.returnId,
            type:'cg',
          }
        });
      }
      window.open(routeUrl.href, "_blank");
    },
    openWindow(documentNum) {
      this.cancelDocumentNum = documentNum
      this.visibleCancel = true
    },
    // 时间排序
    changeFang() {
      if (this.creatDate == 0) {
        this.creatDate = 1;
        this.page.sortOrder = "desc";
      } else if (this.creatDate == 1) {
        this.creatDate = 2;
        this.page.sortOrder = "asc";
      } else {
        this.creatDate = 0;
        this.page.sortOrder = "";
      }
      this.getCommodityList();
    },

    getReason(e) {
      if(!e) {
        this.$message.warning('请输入取消原因')
        return
      }
      let salesData = {
        cisCode:this.$store.state.user.userInfo.customer.cisCode,
        documentNum:this.cancelDocumentNum,
        message:e,
      }
      this.pageLoadFlag = true
      salesReturn(salesData).then(res=>{
        let data = JSON.parse(res.data)
        if(data.code == '0') {
          this.$message.success('取消成功')
        } else {
          this.$message.warning(data.msg)
        }
        this.getCommodityList()
        this.pageLoadFlag = false
      })
    },
    // 跳转查看详情
    gotodetail(item) {
      let routeUrl = this.$router.resolve({
        path: '/channelOrder/detail',
        query: {
          id:item.purchaseOrderId,
          source:'th'
        }
      });
      window.open(routeUrl.href, "_blank");
    },

    // 跳转入库操作
    ruku(id) {
      let routeUrl = this.$router.resolve({
        path: '',
        query: {
          id,
        }
      });
      window.open(routeUrl.href, "_blank");
    },

    //表单筛选
    inputSearch(data) {
      this.paramList = data[1].paramList
      this.page.pageNo = 1;
      this.goodsList = []
      this.goodsNmuber=0
      this.pageLoadFlag = true;
      this.getCommodityList();
    },

    // 全选
    chooseAll(e) {
      if (e.target.checked) {
        for (let i = 0; i < this.goodsList.length; i++) {
          this.goodsList[i].checked = true;
        }
      } else {
        for (let i = 0; i < this.goodsList.length; i++) {
          this.goodsList[i].checked = false;
        }
      }
      this.$forceUpdate();
    },

    // 导出
    exportClick() {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        terms: {
          ...this.requestdata,
          paramList:this.paramList,

        },
        page: this.page
      }
      this.orderLoading = true;
      exportOrder(data).then(res => {
        this.orderLoading = false;
        Util.blobToJson(res.data).then(content => {
          if (content && content.msg == 'success') {
            this.$message.success('下载成功，请到下载中心查看!')
          }
        }).catch(err => {
          Util.downloadFile(res.data, '采购退货列表.xls')
        }).finally(() => {
          this.orderLoading = false;
        })
      })
    },

    // 商品选择
    chooseItem(data, index, e) {
      this.goodsList[index].checked = e.target.checked;
      var pushY = this.invalidList.indexOf(data);
      if (pushY == -1) {
        this.invalidList.push(data);
      } else {
        this.invalidList.splice(pushY, 1);
      }
      this.$forceUpdate()
    },

    // 分销采购退货列表
    getCommodityList() {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        terms: {
          ...this.requestdata,
          paramList:this.paramList,

        },
        page: this.page
      }
      // this.requestdata = data;
      this.pageLoadFlag = true;
      getPurchaseReturnList(data).then(res => {
        this.pageLoadFlag = false;
        var realDate = JSON.parse(res.data);
        if (realDate.code == 0){
          this.goodsNmuber = realDate.page.totalCount;
          this.loadings = false;
          this.goodsList = realDate.data
          for (let i = 0; i < this.goodsList.length; i++) {
            this.goodsList[i].checked = false;
          }
        }else {
          this.goodsNmuber = 0;
          this.loadings = false;
          this.goodsList = []
        }

      })
    },
    // 加载更多
    loadMore() {
      this.loadings = true;
      this.page.pageNo++;
      this.pageLoadFlag = true;
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        terms: {
          ...this.requestdata,
          paramList:this.paramList,
        },
        page: this.page
      }
      // this.requestdata = data;
      this.pageLoadFlag = true;
      getPurchaseReturnList(data).then(res => {
        this.pageLoadFlag = false;
        this.loadings = false
        var realDate = JSON.parse(res.data);
        if(realDate.code == 0){
          this.goodsList = this.goodsList.concat(realDate.data);
        }
      })
    }
  }
}